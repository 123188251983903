<template>
  <div class="container grid-md about">
    <div class="columns">
      <div class="column col-12">
        <div class="tabs">
          <div class="tabs__navigation tabs__navigation--sub-page">
            <router-link class="tabs__navigation-item" to="/about/doing-things-the-nordic-way">{{ $t('navigation.about_us') }}</router-link>
            <router-link class="tabs__navigation-item" to="/about/team"
              v-bind:class="{'router-link-exact-active' : this.$route.name === 'MarcusHonkanen' | this.$route.name === 'FannyAberg' | this.$route.name === 'ChristianFuchs' | this.$route.name === 'ChristopherGrahn' | this.$route.name === 'JasminChristensen' | this.$route.name === 'LottaVeikonheimo' | this.$route.name === 'AnjaGolumbeck'}"
            >{{ $t('navigation.team') }}</router-link>
            <router-link class="tabs__navigation-item" to="/about/network">{{ $t('navigation.network') }}</router-link>
            <router-link class="tabs__navigation-item" to="/about/advisory-board" v-bind:class="{'router-link-exact-active' : this.$route.name === 'AndreasStraarup' | this.$route.name === 'BrigitteSchnakenbourg' | this.$route.name === 'OutiSortino' | this.$route.name === 'AnnaMindeloef'}">{{ $t('navigation.advisory_board') }}</router-link>
          </div>
          <div class="tabs__content">
            <div class="tabs__content-item">
              <router-view/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {},
}
</script>

<style lang="scss" scoped>
</style>
